<template>
  <div class="grid gap10" style="position: relative;top: 0px;width: 65%">
    <div class="btn  blue imgupload" style="font-size: 0.13rem" @click="editable && chooseFile()" >文件上传</div>

    <input id="fileUpload" type="file" name="file" accept="image/*,.pdf" @change="doUpload">

    <div v-for="(fh, idx) of fileLists" style="width: 100%">
      <div @click="preview(fh.fid,fh.fileName)" style="display: inline-block">
        <span class="filenamespan" style="color: #4A89DC" >{{fh.fileName}}</span>   <span style="display: inline-block;width: 65px;">{{fh.fileSize}} kb</span>
      </div>

      <div style="display: inline-block;text-align: right">
        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><button style="background-color: #E9573F;color: white;border: 0px;margin-left: 20px" @click="editable && removeFile(fh,$event)">删除</button>
      </div>
      </div>
      <div>
        <span style="color: #999999;font-size: 10px">仅支持 jpg,png,pdf,大小不超过10m,最多上传10个文件</span>
      </div>
    <mt-popup
        v-model="previewPopShow"
        position="right" popup-transition="popup-fade" class="preview-popup">
      <img :src="previewUrl">
    </mt-popup>
  </div>
</template>

<style lang="scss" scoped>
#fileUpload {
  display: none;
}
.filenamespan{
  display: inline-block;
  width: 90px !important;
  height: 0.3rem;
  float: left !important;
  overflow-x: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis !important;

}
.imgupload{
  height: 0.29rem;
}
.file-upload-wrapper {
  position: relative;
  padding-bottom: 100%;
  background: #eee;
  color: #ccc;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.file-upload-inner {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;

  .file-upload-add {
    width: 100%;
    height: 100%;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .file-upload-remove {
    position: absolute;
    z-index: 3;
    right: -5px;
    top: -5px;
    width: 20px;
    height: 20px;
    font-size: 16px;
    //border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: #666;
    color: #fff;
  }

  img {
    object-fit: cover;
  }
}

.preview-popup {
  width: 60%;
  left: 20%;
}
</style>

<script>
import {Toast} from "mint-ui";
import ImageCompressor from "@/plugins/image-compressor";

export default {
  name: "FileUpload2",
  props: ["maxSize", "space", "biz", "bizId", "editable","tabindex"],
  components:{
    ImageCompressor
  },
  data() {
    return {
      curHolderIdx: 0,
      fileHolders: [],
      fileLists: [],
      previewPopShow: false,
      previewUrl: '',
      tabnum: 0,
      init: false
    }
  },
  mounted() {
    this.appendAddIfPossible()
  },
  methods: {

    //传回uuid 数组 直接添加
    initFilesByUuids(uuids,tabIndex){
      this.init=true
      this.tabnum=tabIndex
      this.fileHolders = []
      const that = this
      for (let i =0;i<uuids.length;i++){
        that.fileHolders.push({
          fid: uuids[i]
        })
      }
      that.getfileByuuid()
      that.appendAddIfPossible()

    },
    async getfileByuuid(){

      const that=this
      that.fileLists=[]
      var list=this.fileHolders
      if(list.length>0){
        for (var i=0;i<list.length;i++){
          var fd=list[i]
          if(fd.fid!=''&&fd.fid!=null){
           await this.$http.get("/portal/equipmentMaintain/file?uid="+fd.fid,true).then(res=>{
              that.fileLists.push({
                fileName: res.name,
                fileSize: (res.size/1024).toFixed(1),
                fid: res.uuid
              })
            })
            if(!this.init){
              that.getuuidsByfileNumber()
            }

          }
        }
      }
    },
    getFids(){
      const fids = []
      for (let i = 0; i < this.fileHolders.length; i++) {
        const fid = this.fileHolders[i].fid
        if (fid) {
          fids.push(fid)
        }
      }
      return fids
    },
    toImgUrl(fid) {
      return `${this.imgUrl}/${fid}`
    },
    chooseFile() {
      if(this.fileHolders[this.fileHolders.length-1].fid=='') {
        // this.curHolderIdx = this.fileHolders.indexOf()
        var event = new MouseEvent('click');
        document.getElementById("fileUpload").dispatchEvent(event)
      }else {
          Toast("最多可上传"+this.maxSize+"个文件！")

      }


    },
    removeFile(fh, event){
      event.preventDefault()
      event.stopPropagation()
      var list=this.fileHolders;
      for (var i=0;i<list.length;i++){
        if(fh.fid==list[i].fid){
          this.fileHolders.splice(this.fileHolders.indexOf(list[i]), 1)
        }
      }

      this.fileLists.splice(this.fileLists.indexOf(fh),1)
      this.appendAddIfPossible()
      this.getuuidsByfileNumber()
      // this.$http.post(`/fs/delPhysic/${fh.fid}`)
    },
    appendAddIfPossible() {
      if (this.fileHolders.length < this.maxSize) {
        if (this.fileHolders.length>0 && !this.fileHolders[this.fileHolders.length-1].fid){
          return
        }
        if (this.editable) {
          this.fileHolders.push({
            fid: ''
          })
        }
      }
    },
    async  doUpload() {
        var isImg=true
        var isok=false
        this.init=false
        this.tabnum=this.tabindex
        const that = this
        let fd = new FormData()
        let upload = document.getElementById("fileUpload")
        console.log("upload",upload.files[0])
        fd.append("file", upload.files[0])
      console.log("FD1",upload.files[0].name)
      var fileType=upload.files[0].type
        var type= fileType.split("/")
        if(type[0]=='image'){
          isok=true
        }else if(type[1]=='pdf') {
          isImg=false
          isok=true
        }
        var that1=this

          if(isok){
            if(isImg){
              fd.delete("file");
              var imgCompressor = new ImageCompressor()
              imgCompressor.compress({
                file: upload.files[0],
                optimizedSize: 1000*1024,
                width: 1000,
                filename: upload.files[0].name,
                quality: 0.5,
                done: function(res){
                  console.log("res",res);
                  if(res && res.code == 0){
                    let data = res.data;
                    //新建一个 FormData 对象
                    fd.append("file", data.file); //加入文件对象
                    console.log("FD2",fd.get("file"))
                    let query = `/fs/upload?space=${that1.space}&zoom=2`
                    if (that1.biz) {
                      query += `&biz=${that1.biz}`
                    }
                    if (this.bizId) {
                      query += `&bizId=${that1.bizId}`
                    }
                    that1.$http.post(query, fd, {
                      headers: {'Content-Type': "multipart/form-data"}
                    }).then(result => {
                      if (result.success) {
                        const f = result.data[0]
                        that.fileHolders[that.fileHolders.length-1].fid = f.id
                        that.getfileByuuid()
                        that.appendAddIfPossible()
                      }
                    })
                  }
                }
              })
            }else {
              console.log("FD2",fd.get("file"))
              let query = `/fs/upload?space=${that1.space}&zoom=2`
              if (that1.biz) {
                query += `&biz=${that1.biz}`
              }
              if (this.bizId) {
                query += `&bizId=${that1.bizId}`
              }
              that1.$http.post(query, fd, {
                headers: {'Content-Type': "multipart/form-data"}
              }).then(result => {
                if (result.success) {
                  const f = result.data[0]
                  that.fileHolders[that.fileHolders.length-1].fid = f.id
                  that.getfileByuuid()
                  that.appendAddIfPossible()
                  // that.getuuidsByfileNumber()
                }
              })
            }

          }else {
            Toast("请上传正确的文件格式")
          }


    },
    //将fileHolders传回调用页
     getuuidsByfileNumber(){

      var that=this;

      let data={
        tabIndex: this.tabindex,
        files: that.fileLists
      }

      if(this.tabindex==0){
          this.$emit("showfile1list",data);


      }
      if(this.tabindex==1){
       this.$emit("showfile2list",data);
      }

    },
    preview(imgUrl,fileName) {
      // window.open(`${this.apiUrl}/fs/${imgUrl}`);
      var last= fileName.split('.')
      var filetype=".jpeg,.jpg,.gif,.bmp,.png,.tif,.webp"
      if(filetype.indexOf(last[last.length-1])>0){
        this.previewPopShow = true
        this.previewUrl = this.toImgUrl(imgUrl)
        // window.open(this.previewUrl)
      }else {
        window.open(`${this.apiUrl}/fs/${imgUrl}`);
      }


    }
  }
}
</script>
